.outerContainer {
  color-scheme: light;
  font-feature-settings: "kern";
  font-family: Inter;
  color: #1A202C;
  list-style: none;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  word-wrap: break-word;
  border-color: #E2E8F0;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  line-height: 0;
  --switch-track-diff: calc(var(--switch-track-width) - var(--switch-track-height));
  --switch-thumb-x: var(--switch-track-diff);
  --switch-track-width: 1.875rem;
  --switch-track-height: 1rem;
  width: 36px;
  height: 24px;
}

.checkboxLabel {
  color-scheme: light;
  font-feature-settings: "kern";
  font-family: var(--chakra-fonts-body);
  line-height: var(--chakra-lineHeights-base);
  list-style: none;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  word-wrap: break-word;
  border-color: var(--chakra-colors-chakra-border-color);
  display: block;
  text-align: start;
  margin-inline-end: var(--chakra-space-3);
  font-weight: var(--chakra-fontWeights-medium);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  opacity: 1;
  margin-bottom: 0px;
  font-size: var(--chakra-fontSizes-sm);
  margin-right: 8px;
  color: var(--chakra-colors-gray-c600);
}

.checkboxLabel[data-checked] {
  color: var(--chakra-colors-success-700);
}

.checkboxGroup {
  font-family: var(--chakra-fonts-body);
  color: var(--chakra-colors-chakra-body-text);
  line-height: var(--chakra-lineHeights-base);
  list-style: none;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  word-wrap: break-word;
  border-color: var(--chakra-colors-chakra-border-color);
  width: 100%;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.input {
  list-style: none;
  --switch-track-diff: calc(var(--switch-track-width) - var(--switch-track-height));
  --switch-thumb-x: var(--switch-track-diff);
  --switch-track-width: 1.875rem;
  --switch-track-height: var(--chakra-sizes-4);
  word-wrap: break-word;
  font-family: inherit;
  font-size: 100%;
  box-sizing: border-box;
  line-height: inherit;
  color: inherit;
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}

.switchTrack {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  box-sizing: content-box;
  cursor: pointer;
  border-radius: var(--chakra-radii-full);
  padding: var(--chakra-space-0-5);
  width: 36px;
  height: 20px;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-fast);
  --switch-bg: var(--chakra-colors-gray-c500);
  background: var(--switch-bg);
}

.switchTrack[aria-checked=true], .switchTrack[data-checked] {
  --switch-bg: var(--chakra-colors-success-600);
}

.switchThumb {
  border-color: var(--chakra-colors-chakra-border-color);
  background: var(--chakra-colors-white);
  transition-property: transform;
  transition-duration: var(--chakra-transition-duration-normal);
  border-radius: inherit;
  width: 20px;
  height: 20px;
}

.switchThumb[data-checked] {
  -webkit-transform: translateX(16px);
  -moz-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.switchThumb[aria-checked=true], .switchThumb[data-checked] {
  -webkit-transform: translateX(16px);
  -moz-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

.switchTrack:disabled, .switchTrack[disabled], .switchTrack[aria-disabled=true], .switchTrack[data-disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
