.button {
  color-scheme: light;
  list-style: none;
  box-sizing: border-box;
  word-wrap: break-word;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  cursor: pointer;
  background: transparent;
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 26px;
  border-radius: 8px;
  font-weight: 500;
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: 14px;
  padding-inline-start: 18px;
  padding-inline-end: 18px;
  padding-block-start: 10px;
  padding-block-end: 10px;
  background-color: var(--chakra-colors-white);
  color: var(--chakra-colors-gray-c900);
  border: 1px solid;
  border-color: var(--chakra-colors-gray-c400);
  transition: background-color 0.2s ease-out,color 0.2s ease-out,outline 0.2s ease-out,border-color 0.2s ease-out;
  padding: 8px 12px 8px 10px;
}

.button:hover {
  background-color: var(--chakra-colors-gray-c200);
}

.icon {
  color-scheme: light;
  list-style: none;
  font-family: inherit;
  text-transform: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  line-height: 26px;
  font-weight: 500;
  font-size: 14px;
  color: var(--chakra-colors-gray-c900);
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  word-wrap: break-word;
  border-color: var(--chakra-colors-chakra-border-color);
  display: inline-flex;
  align-self: center;
  flex-shrink: 0;
  margin-inline-end: 0.5rem;
}
