.scrollShadow {
  background:
    linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size:
    100% 20px,
    100% 20px,
    100% 8px,
    100% 8px;
  background-attachment: local, local, scroll, scroll;

  /* width and height of the scrollbar track */
  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  /* style of the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: --var(chakra-colors-gray-400);
    border-radius: 2px;
  }

  /* style of the scrollbar track */
  ::-webkit-scrollbar-track {
    background: --var(chakra-colors-white);
    border-radius: 2px;
  }
}